.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.logo{
  height:200px;
  width: 400px;
}

.bContainer{
  min-height:60px;
  padding-bottom: 10px;
}

.tile-text-p{
  font-size: 23px;
  color: black;
}

.tile-text-d{
  font-size: 15px;
  color: black;
  text-align: center;
  height: 40px;
}

.tile{
  height:550px;
  width: 450px;
  min-width: 400px;
}


.tile-pic{
  height:345px;
  width: 345px;
}

.tile-about{
  height:500px;
  width: 400px;
}

.gap{
  height:1px;
  width: 100px;
}

.spacer{
  height:1px;
  width: 100px;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-footer {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 2vmin);
  color: black;
}

.App-info {
  min-height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(1px + 2vmin);
  color: black;
}

.App-info-test {
  min-height: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px);
  color: black;
}

.App-header-original {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-2 {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.hero-image {
  background-image: url("./assets/dealer2.jpg");
  height: 40vmin;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-text-size {
  font-size:50px
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  width: 60%;
  margin: 20px auto;
  color: black;
  font-size: 20px;
}

.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 2px solid #ccc;
  margin: 0 10px;
}